<template>
  <nav class="sticky top-0 bg-white border-b border-gray-200 z-10">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16 space-x-4">
        <div class="flex">

          <!--          Sidebar Collapser-->
          <div v-if="isAnalyticsPage"
               class="pl-2 flex-shrink-0 flex items-center mr-2 -ml-4 sm:-ml-6 lg:-ml-8"
          >
            <button type="button"
                    class="h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-smoke-500"
                    @click="sidebarStore.toggle"
            >
              <VIcon :symbol="sidebarStore.isOpened ? 'menu_open' : 'menu'"></VIcon>
            </button>
          </div>

          <div class="flex-shrink-0 flex items-center">
            <VLink :to="{ name: 'dashboard' }">
              <VLogo class="h-8 w-auto"></VLogo>
            </VLink>
          </div>
        </div>

        <!--        Google Search-->
<!--        <div class="hidden md:flex items-center max-w-lg w-full lg:max-w-2xl">
          <label for="search" class="sr-only">Search</label>
          <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                    fill="#FFC107"/>
                <path
                    d="M3.15302 7.3455L6.43851 9.755C7.32752 7.554 9.48052 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.15902 2 4.82802 4.1685 3.15302 7.3455Z"
                    fill="#FF3D00"/>
                <path
                    d="M12 22C14.583 22 16.93 21.0115 18.7045 19.404L15.6095 16.785C14.5717 17.5742 13.3037 18.001 12 18C9.39897 18 7.19047 16.3415 6.35847 14.027L3.09747 16.5395C4.75247 19.778 8.11347 22 12 22Z"
                    fill="#4CAF50"/>
                <path
                    d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                    fill="#1976D2"/>
              </svg>
            </div>
            <input id="search" name="search"
                   class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-smoke-500 focus:border-smoke-500 sm:text-sm"
                   placeholder="Search links, articles, media" type="search">
          </div>
        </div>-->

        <div class="flex items-center flex-shrink-0">
          <!-- Profile dropdown -->
          <VDropdown placement="bottom-end">
            <button type="button"
                    class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-smoke-500"
            >
              <span class="sr-only">Open user menu</span>
              <img class="h-8 w-8 rounded-full"
                   :src="currentUser.image"
                   :alt="currentUser.name">
            </button>

            <template #content="{closeDropdown}">
              <div v-if="isAuth"
                   class="py-1"
              >
                <DropdownItem label="Login"
                              :to="{ name: 'login' }"
                              icon="login"
                              @click="closeDropdown"
                />
              </div>
              <div v-else class="divide-y">
                <div class="py-1">
                  <DropdownItem label="Analytics"
                                :to="{ name: 'analytics' }"
                                icon="query_stats"
                                @click="closeDropdown"
                  />
                </div>
                <div class="py-1">
                  <DropdownItem label="Logout"
                                icon="logout"
                                @click="logout"
                  />
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {useSidebarStore} from "@/stores/sidebarStore";
import {mapActions, mapState} from "pinia";
import {useAuth} from "@/stores/auth";

export default {
  name: "TheNavbar",
  data() {
    return {
      isAuth: false,
    }
  },
  computed: {
    ...mapState(useAuth, ['currentUser']),
    isAnalyticsPage() {
      return Boolean(this.$route.matched.find(({name}) => name === "analytics"));
    },
  },
  setup() {
    const sidebarStore = useSidebarStore();

    return {
      sidebarStore,
    }
  },
  methods: {
    ...mapActions(useAuth, ['purgeAuth']),
    logout() {
      this.purgeAuth();
      this.$router.push({name: 'login'});
    }
  }
}
</script>
