<template>
  <Popper v-bind="$attrs"
          :offsetDistance="offsetDistance"
  >
    <slot></slot>

    <template #content="{close, isOpen}">
      <div class="rounded-md shadow-lg"
           :class="width"
      >
        <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <slot name="content"
                v-bind="{closeDropdown: close, isDropdownOpen: isOpen}"
          ></slot>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script>
import {defineComponent} from "vue";
import Popper from "vue3-popper";

export default defineComponent({
  name: "VDropdown",
  components: {
    Popper,
  },
  props: {
    width: {
      type: String,
      default: "w-56"
    },
    offsetDistance: {
      type: String,
      default: "8",
    },
  },
});
</script>

<style scoped>

</style>