<template>
  <div class="py-12 text-center">
    <div v-if="icon" class="mx-auto text-gray-400">
      <VIcon :symbol="icon" size="48" weight="300"></VIcon>
    </div>
    <h3 class="mt-2 font-medium text-gray-900">
      {{ label }}
    </h3>
    <p v-if="helpText" class="mt-1 text-sm text-gray-500">
      {{ helpText }}
    </p>
    <div v-if="btnLabel" class="mt-6">
      <VButton :label="btnLabel" :icon="btnIcon" @click="$emit('click')"></VButton>
    </div>
  </div>

</template>

<script>
export default {
  name: "EmptyState",

  emits: ['click'],

  props: {
    label: String,
    helpText: String,
    icon: String,
    btnIcon: String,
    btnLabel: String,
  },
}
</script>

<style scoped>

</style>