import {defineStore} from "pinia";
import JwtService from '@/services/jwt.service';
import ApiService from "@/services/api.service";

export const useAuth = defineStore("auth", {
    state: () => ({
        user: {},
        _isAuthenticated: !!JwtService.getToken()
    }),
    getters: {
        currentUser: state => state.user,
        isAuthenticated: state => state._isAuthenticated
    },
    actions: {
        setAuth(data) {
            this.setUser(data);

            this._isAuthenticated = true;
            JwtService.saveToken(data.accessToken);

            ApiService.init();
        },
        setUser(data) {
            this.user = {
                userId: data.userId,
                name: data.name,
                email: data.email,
                isAdmin: data.isAdmin,
                image: data.image && data.image.length ? data.image : require('@/assets/imgs/profile/default_user.jpg'),
            }
        },
        async checkAuth() {
            if (JwtService.getToken()) {
                try {
                    const { data } = await ApiService.get('/auth/user');
                    this.setUser(data);
                    this._isAuthenticated = true;
                    ApiService.init();
                } catch (errors) {
                    if ([401, 403].includes(errors.response.status))
                        this.purgeAuth();
                }
            } else {
                this.purgeAuth();
            }
        },
        purgeAuth() {
            this._isAuthenticated = false;
            this.user = {};
            JwtService.destroyToken();
        }
    }
});
