import {defineStore} from "pinia";

import {fullTailwindConfig} from "@/helpers";

export const useSidebarStore = defineStore("sidebarStore", {
    state: () => ({
        open: null,
    }),
    actions: {
        toggle() {
            this.open = !this.open;
        },
        close() {
            this.open = false;
        },
        setInitialOpen() {
            this.open = document.body.clientWidth > parseInt(fullTailwindConfig.theme.screens['md']);
        },
    },
    getters: {
        isOpened: (state) => state.open,
    },
});
