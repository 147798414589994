import app from "@/app";

import DropdownItem from "@/components/DropdownItem";
import EmptyState from "@/components/EmptyState";
import VCheckbox from "@/components/VCheckbox";
import VDropdown from "@/components/VDropdown";
import VTextarea from "@/components/VTextarea";
import VTooltip from "@/components/VTooltip";
import VSelect from "@/components/VSelect";
import VButton from "@/components/VButton";
import VLoader from "@/components/VLoader";
import VInput from "@/components/VInput";
import VLabel from "@/components/VLabel";
import VModal from "@/components/VModal";
import VLink from "@/components/VLink";
import VIcon from "@/components/VIcon";
import VLogo from "@/components/VLogo";

app
    .component("DropdownItem", DropdownItem)
    .component("EmptyState", EmptyState)
    .component("VCheckbox", VCheckbox)
    .component("VDropdown", VDropdown)
    .component("VTextarea", VTextarea)
    .component("VTooltip", VTooltip)
    .component("VSelect", VSelect)
    .component("VButton", VButton)
    .component("VLoader", VLoader)
    .component("VInput", VInput)
    .component("VLabel", VLabel)
    .component("VModal", VModal)
    .component("VLink", VLink)
    .component("VIcon", VIcon)
    .component("VLogo", VLogo)
