<template>
  <main>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="py-12 text-center">
        <img class="inline-block w-24 h-24 rounded-full object-cover"
             :src="currentUser.image"
             :alt="currentUser.name"
        >
        <div class="mt-3">
          <p class="text-h5">
            Welcome, {{ currentUser.name }}
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {mapState} from "pinia";
import {useAuth} from "@/stores/auth";

export default {
  name: "DashboardView",
  computed: {
    ...mapState(useAuth, ['currentUser']),
  },
}
</script>
