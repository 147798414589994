<template>
  <img :src="logo" alt="Africa Practice">
</template>

<script>
export default {
  name: "VLogo",
  props: {
    size: {
      type: String,
      default: "48",
      validator(size) {
        return ["16", "32", "48", "128"].includes(size)
      }
    },
  },
  computed: {
    logo() {
      return require(`@/assets/logo/ap-${this.size}.png`);
    },
  },
}
</script>

<style scoped>

</style>