<template>
  <DefaultField :helpText="helpText" :error="error">
    <div class="relative flex items-start">
      <div class="flex items-center h-5">
        <input
            type="checkbox"
            class="w-4 h-4 border-gray-300 rounded text-smoke-600 focus:ring-smoke-500"
            :id="id"
            :checked="modelValue"
            :disabled="disabled"
            @input="$emit('update:modelValue', $event.target.checked)"
        />
      </div>
      <div class="ml-3 text-sm" v-if="label">
        <VLabel :label="label" :for="id"></VLabel>
      </div>
    </div>
  </DefaultField>
</template>

<script>
import DefaultField from "@/components/DefaultField";
import defaultField from "@/mixins/defaultField";

export default {
  name: "VCheckbox",
  mixins: [defaultField],
  emits: ['update:modelValue'],
  components: {
    DefaultField
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    error: String,
    label: String,
    modelValue: {}
  }
};
</script>
