<template>
  <component
      :is="tag"
      :to="to"
      class="text-gray-700 group flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
  >

    <VIcon v-if="icon" :symbol="icon" size="20" class="mr-3 text-gray-400 group-hover:text-gray-500"></VIcon>

    {{ label }}
  </component>
</template>

<script>
export default {
  name: "DropdownItem",
  props: {
    label: [String, Number],
    icon: String,
    to: [String, Object,],
  },
  computed: {
    tag() {
      return this.to ? "router-link" : "button";
    }
  }
}
</script>

<style scoped>

</style>
