<template>
  <span class="material-symbol select-none"
        :class="[
            `material-symbol--opsz-${size}`,
            `material-symbol--wght-${weight}`,
            {'material-symbol--active' : active},
        ]"
  >
    {{ symbol }}
  </span>
</template>

<script>
export default {
  name: "VIcon",
  props: {
    symbol: {
      type: String, //material design symbol name
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '24',
      validator(size) {
        return ["20", "24", "40", "48"].includes(size);
      }
    },
    weight: {
      type: String,
      default: "400",
      validator(weight) {
        return ["100", "200", "300", "400", "500", "600", "700"].includes(weight);
      }
    }
  },
}
</script>

<style scoped>
.material-symbol {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-symbol--opsz-20 {
  font-size: 20px;
  font-variation-settings: 'OPSZ' 20;
}

.material-symbol--opsz-24 {
  font-size: 24px;
  font-variation-settings: 'OPSZ' 24;
}

.material-symbol--opsz-40 {
  font-size: 40px;
  font-variation-settings: 'OPSZ' 40;
}

.material-symbol--opsz-48 {
  font-size: 48px;
  font-variation-settings: 'OPSZ' 48;
}

.material-symbol--wght-100 {
  font-variation-settings: 'wght' 100;
}

.material-symbol--wght-200 {
  font-variation-settings: 'wght' 200;
}

.material-symbol--wght-300 {
  font-variation-settings: 'wght' 300;
}

.material-symbol--wght-400 {
  font-variation-settings: 'wght' 400;
}

.material-symbol--wght-500 {
  font-variation-settings: 'wght' 500;
}

.material-symbol--wght-600 {
  font-variation-settings: 'wght' 600;
}

.material-symbol--wght-700 {
  font-variation-settings: 'wght' 700;
}

.material-symbol--active {
  font-variation-settings: 'FILL' 1;
}
</style>