import axios from "axios";
import JwtService from "@/services/jwt.service";

const ApiService = {
    init() {
        axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

        if (JwtService.getToken()){
            axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(JwtService.getToken());
        }
    },
    query(resource, params = {}) {
        try {
            return axios.get(`${resource}`, { params });
        } catch (error) {
            throw new Error(`[RWV] ApiService ${error}`);
        }
    },
    get(resource, id = "", params = {}) {
        try {
            return axios.get(`${resource}/${id}`, params)
        } catch (e) {
            throw new Error(`[RWV] ApiService ${e}`);
        }
    },
    post(resource, params) {
        return axios.post(`${resource}`, params);
    },
    update(resource, id, params) {
        return axios.put(`${resource}/${id}`, params);
    },
    put(resource, params) {
        return axios.put(`${resource}`, params);
    },
    delete(resource) {
        try {
            return axios.delete(resource);
        } catch (error) {
            throw new Error(`[RWV] ApiService ${error}`);
        }
    }
};

export default ApiService;
