import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "/tailwind.config";

export function isObject(value) {
    return (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
    );
}

export const fullTailwindConfig = resolveConfig(tailwindConfig);
