<template>
  <DefaultField :helpText="helpText" :error="error">
    <div class="relative">

      <div v-if="hasIcon"
           class="absolute inset-y-0 flex items-center pointer-events-none"
           :class="[
               (iconPosition === 'leading') ? 'left-0 pl-3' : 'right-0 pr-3',
               error ? 'text-red-500' : 'text-gray-400'
             ]"
      >
        <slot name="icon" :classes="iconClasses">
          <VIcon :symbol="icon" size="20"></VIcon>
        </slot>
      </div>

      <input :type="type"
             ref="input"
             v-bind="$attrs"
             :value="modelValue"
             :disabled="disabled"
             :placeholder="placeholder"
             :spellcheck="spellcheck"
             class="py-3 border focus:outline-none block w-full text-base sm:text-sm rounded"
             :class="[
               error
               ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
               : 'border-gray-300 text-gray-900 focus:ring-smoke-500 focus:border-smoke-500 shadow-sm',
               {'cursor-not-allowed opacity-50' : disabled},
               {'pl-10': hasIcon && (iconPosition === 'leading')},
               {'pr-10': hasIcon && (iconPosition === 'trailing')},
             ]"
             @input="onInput"
      >

    </div>
  </DefaultField>
</template>

<script>
import DefaultField from "@/components/DefaultField";
import defaultField from "@/mixins/defaultField";

export default {
  inheritAttrs: false,
  mixins: [defaultField],
  emits: ['update:modelValue'],
  components: {
    DefaultField
  },
  props: {
    modelValue: String,
    type: {
      type: String,
      default: "text"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false
    },
    spellcheck: {
      type: Boolean,
      default: false
    },
    icon: String,
    iconPosition: {
      type: String,
      default: "leading",
      validator(value) {
        return ["leading", "trailing"].includes(value)
      }
    },
    focus: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    hasIcon() {
      return Boolean(this.icon || this.$slots.icon);
    },
    iconClasses() {
      return "h-5 w-5";
    },
  },
  mounted() {
    if (this.focus) this.$refs.input.focus();
  },
  methods: {
    onInput(e) {
      if (this.disabled) return;
      this.$emit('update:modelValue', e.target.value);
    }
  },
}
</script>

<style scoped>

</style>
