<template>
  <slot></slot>

  <Transition
      enter-active-class="transition-[opacity,transform] duration-300 ease-in"
      enter-from-class="-translate-x-2 opacity-0"
      leave-active-class="transition-opacity duration-200 ease-out"
      leave-to-class="opacity-0"
      mode="out-in"
  >
    <p v-if="error"
       v-text="error"
       class="mt-2 text-sm text-red-600"
    ></p>
    <p v-else-if="helpText"
       class="mt-2 text-sm text-gray-500"
    >
      {{ helpText }}
    </p>
  </Transition>
</template>

<script>
import defaultField from "@/mixins/defaultField";

export default {
  name: "DefaultField",
  mixins: [defaultField],
}
</script>

<style scoped>

</style>
