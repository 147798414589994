<template>
  <button type="button"
          class="inline-flex items-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
          :class="[
              toneClasses,
              sizeClasses,
              {'cursor-not-allowed opacity-50' : disabled},
              {'cursor-wait hover:opacity-50' : busy},
              ]"
          :disabled="isDisabled"
  >
    <span v-if="hasIcon || busy"
          :class="{'order-last': iconPosition === 'trailing'}"
    >
      <svg v-if="busy"
           class="animate-spin"
           :class="iconClasses"
           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
      >
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <slot v-else
            name="icon"
            :classes="iconClasses"
      >
        <div :class="iconClasses">
          <VIcon :symbol="icon"
                 :active="iconActive"
                 size="20"
          ></VIcon>
        </div>
      </slot>
    </span>

    <template v-if="iconPosition !== 'only'">
      <slot>
        {{ busy ? (busyLabel || label) : label }}
      </slot>
    </template>
  </button>
</template>

<script>
import tailwindThemeing from "@/mixins/tailwindThemeing";

export default {
  name: "VButton",
  mixins: [tailwindThemeing],
  props: {
    label: String,
    busyLabel: String,
    icon: String,
    iconActive: {
      type: Boolean,
      default: false,
    },
    busy: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tone: {
      type: String,
      default: "primary",
      validator(value) {
        return ["primary", "secondary", "white"].includes(value)
      }
    },
    size: {
      type: String,
      default: "base",
      validator(value) {
        return ["xs", "sm", "base", "l", "xl"].includes(value)
      }
    },
    iconPosition: {
      type: String,
      default: "leading",
      validator(value) {
        return ["leading", "trailing", "only"].includes(value)
      }
    },
    rounded: {
      type: Boolean,
      default: false
    },
    circular: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasIcon() {
      return Boolean(this.icon || this.$slots.icon);
    },
    isDisabled() {
      return this.disabled || this.busy;
    },
    toneClasses() {
      switch (this.tone) {
        case "primary":
          return `${this.isDisabled ? '' : 'hover:bg-primary-700'} border-transparent shadow-sm text-white bg-primary-600 focus:ring-primary-500`
        case "secondary":
          return `${this.isDisabled ? '' : 'hover:bg-primary-200'} border-transparent text-primary-700 bg-primary-100 focus:ring-primary-500`
        case "white":
          return `${this.isDisabled ? '' : 'hover:bg-gray-50'} border-gray-300 shadow-sm text-gray-700 bg-white focus:ring-primary-500`
      }

      return null;
    },
    sizeClasses() {
      switch (this.size) {
        case "xs":
          return `${this.radiusClasses} ${this.paddingClasses} text-xs`
        case "sm":
          return `${this.radiusClasses} ${this.paddingClasses} text-sm leading-4`
        case "base":
          return `${this.radiusClasses} ${this.paddingClasses} text-sm`
        case "l":
        case "xl":
          return `${this.radiusClasses} ${this.paddingClasses} text-base`
      }

      return null;
    },
    radiusClasses() {
      if (this.rounded || this.circular) {
        return "rounded-full";
      }

      switch (this.size) {
        case "xs":
          return "rounded"
        case "sm":
        case "base":
        case "l":
        case "xl":
          return "rounded-md"
      }

      return null;
    },
    paddingClasses() {
      if (this.rounded) {
        switch (this.size) {
          case "xs":
            return "px-3 py-1.5"
          case "sm":
            return "px-3.5 py-2"
          case "base":
            return "px-4 py-2"
          case "l":
            return "px-5 py-2"
          case "xl":
            return "px-6 py-3"
        }
      }

      if (this.circular) {
        switch (this.size) {
          case "xs":
            return "p-1"
          case "sm":
            return "p-1.5"
          case "base":
          case "l":
            return "p-2"
          case "xl":
            return "p-3"
        }
      }

      switch (this.size) {
        case "xs":
          return "px-2.5 py-1.5"
        case "sm":
          return "px-3 py-2"
        case "base":
        case "l":
          return "px-4 py-2"
        case "xl":
          return "px-6 py-3"
      }

      return null;
    },
    iconClasses() {
      if (this.iconPosition === "only") {
        return this.iconSize;
      }

      if (this.iconPosition === "leading") {
        switch (this.size) {
          case "sm":
            return `-ml-0.5 mr-2 ${this.iconSize}`
          case "base":
            return `-ml-1 mr-2 ${this.iconSize}`
          case "l":
          case "xl":
            return `-ml-1 mr-3 ${this.iconSize}`
        }
      }

      if (this.iconPosition === "trailing") {
        switch (this.size) {
          case "sm":
            return `-mr-0.5 ml-2 ${this.iconSize}`
          case "base":
            return `-mr-1 ml-2 ${this.iconSize}`
          case "l":
          case "xl":
            return `-mr-1 ml-3 ${this.iconSize}`
        }
      }

      return null;
    },
    iconSize() {
      if (this.iconPosition === "only") {
        switch (this.size) {
          case "xs":
          case "sm":
          case "base":
            return "h-5 w-5"
          case "l":
          case "xl":
            return "h-6 w-6"
        }
      }

      switch (this.size) {
        case "sm":
          return "h-4 w-4"
        case "base":
        case "l":
        case "xl":
          return "h-5 w-5"
      }

      return null;
    }
  }
}
</script>

<style scoped>
button {
  --primary-100: v-bind(activeColor [100]);
  --primary-200: v-bind(activeColor [200]);
  --primary-500: v-bind(activeColor [500]);
  --primary-600: v-bind(activeColor [600]);
  --primary-700: v-bind(activeColor [700]);
}
</style>