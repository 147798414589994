<template>
  <div>
    <!-- Trigger -->
    <span v-if="$slots.trigger"
          @click.stop="open"
    >
      <slot name="trigger"/>
    </span>

    <!-- Modal -->
    <Teleport to="#modals">
      <TransitionRoot :show="show" appear>
        <div class="relative z-10">
          <!-- Overlay -->
          <TransitionChild
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
              as="template"
              appear
              @before-enter="overlayTransitionLeaveFinished = false"
              @after-leave="overlayTransitionLeaveFinished = true"
          >
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          </TransitionChild>

          <div class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex justify-center min-h-full p-4 text-center"
                 :class="position"
            >
              <!-- Panel -->
              <TransitionChild
                  enter="ease-out duration-300"
                  enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enter-to="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leave-from="opacity-100 translate-y-0 sm:scale-100"
                  leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  as="template"
                  appear
                  @before-enter="panelTransitionLeaveFinished = false"
                  @after-leave="panelTransitionLeaveFinished = true"
              >
                <div v-click-outside="close"
                     class="relative bg-white rounded-lg w-full px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-6"
                     :class="[width]"
                >
                  <div>
                    <h3 v-if="title"
                        class="text-lg text-center leading-6 font-medium text-gray-900"
                    >
                      {{ title }}
                    </h3>

                    <slot v-bind="{closeModal: close}"></slot>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </div>
      </TransitionRoot>
    </Teleport>
  </div>
</template>

<script>

import {TransitionRoot, TransitionChild} from "@headlessui/vue";

export default {
  name: "VModal",
  emits: ["close"],
  components: {
    TransitionRoot,
    TransitionChild
  },
  props: {
    title: String,
    triggerShow: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "max-w-2xl"
    },
    position: {
      type: String,
      default: 'items-start sm:items-center'
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      overlayTransitionLeaveFinished: false,
      panelTransitionLeaveFinished: false,
    }
  },
  watch: {
    transitionLeaveFinished(finished) {
      if (finished === true) this.$emit("close");
    },
  },
  computed: {
    transitionLeaveFinished() {
      return this.overlayTransitionLeaveFinished && this.panelTransitionLeaveFinished;
    },
  },
  created() {
    this.show = this.triggerShow;
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") this.close();
    });
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      if (this.preventClose) return

      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>