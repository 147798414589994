import {isObject, fullTailwindConfig} from "@/helpers";

const PROJECT_COLORS_CONFIG = fullTailwindConfig.theme.colors;

const PROJECT_COLORS = Object.keys(PROJECT_COLORS_CONFIG);
const PROJECT_COLORS_SHADES = PROJECT_COLORS.filter((color) => isObject(PROJECT_COLORS_CONFIG[color]));

function isShadeColor(color) {
    return PROJECT_COLORS_SHADES.includes(color);
}

const tailwindThemeing = {
    props: {
        color: {
            type: String,
            default: "smoke",
            validator: (color) => isShadeColor(color)
        },
    },
    computed: {
        activeColor() {
            return isShadeColor(this.color) ? PROJECT_COLORS_CONFIG[this.color] : null;
        }
    },
};

export default tailwindThemeing;
