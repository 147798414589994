<template>
  <TheNavbar></TheNavbar>
  <RouterView></RouterView>
</template>

<script>
import TheNavbar from "@/views/layouts/partials/TheNavbar";

export default {
  name: "AppLayout",
  components: {
    TheNavbar,
  }
}
</script>

<style scoped>

</style>