<template>
  <label class="inline-block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
         :class="{'after:hidden' : !required}"
  >
    {{ label }}
  </label>
</template>

<script>
export default {
  name: "VLabel",
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>