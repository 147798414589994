<template>
  <DefaultField :helpText="helpText" :error="error">
    <textarea v-bind="$attrs"
              :value="modelValue"
              :disabled="disabled"
              :spellcheck="spellcheck"
              ref="textarea"
              :rows="rows"
              class="py-3 border focus:outline-none block w-full text-base sm:text-sm rounded-md"
              :class="[
                 error
                 ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                 : 'border-gray-300 text-gray-900 focus:ring-smoke-500 focus:border-smoke-500 shadow-sm',
                 {'cursor-not-allowed opacity-50' : disabled},
               ]"
              @input="onInput"
    ></textarea>
  </DefaultField>
</template>

<script>
import DefaultField from "@/components/DefaultField";
import defaultField from "@/mixins/defaultField";

export default {
  name: "VTextarea",
  inheritAttrs: false,
  mixins: [defaultField],
  emits: ['update:modelValue'],
  components: {
    DefaultField
  },
  props: {
    modelValue: String,
    rows: {
      type: String,
      default: "4"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spellcheck: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onInput(e) {
      if (this.disabled) return
      this.$emit('update:modelValue', e.target.value);
    }
  },
}
</script>

<style scoped>

</style>