<template>
  <DefaultField :helpText="helpText" :error="error">
    <div :class="[classes, error ? 'validation-error' : '']">
      <VueMultiselect
          :label="label"
          :model-value="modelValue"
          :track-by="trackBy"
          :options="options"
          :multiple="multiple"
          :taggable="taggable"
          :allow-empty="allowEmpty"
          :placeholder="placeholder"
          :select-label="selectLabel"
          :close-on-select="closeOnSelect"
          :internal-search="searchable"
          v-bind="!allowEmpty ? { 'deselect-label': '' } : {}"
          @tag="$emit('tag', $event)"
          @update:model-value="$emit('update:modelValue', $event)"
      >
        <template #option="{ option }" v-if="$slots.option">
          <slot name="option" :option="option"></slot>
        </template>
        <template #tag="{ option, remove }">
                <span class="multiselect__tag">
                    <span>{{ option[label] }}</span>
                    <i @click="remove(option)" tabindex="1" class="multiselect__tag-icon"></i>
                </span>
        </template>
        <template #noResult>
          <slot name="noResult">
            No elements found. Consider changing the search query.
          </slot>
        </template>
      </VueMultiselect>
    </div>
  </DefaultField>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import DefaultField from "@/components/DefaultField";
import defaultField from "@/mixins/defaultField";
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  name: "VSelect",
  inheritAttrs: false,
  mixins: [defaultField],
  emits: ['update:modelValue', 'tag'],
  components: {
    VueMultiselect,
    DefaultField,
  },
  props: {
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    classes: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Select option'
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'name'
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    modelValue: {},
    selectLabel: {
      type: String,
      default: 'Press enter to select'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onChangeBlur(e) {
      if (this.disabled) return
      this.$emit('update:modelValue', e.target.value);
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .multiselect {
  &__option.multiselect__option--highlight {
    background-color: #f1f3f2;
    color: #0d1c29;
  }

  &__option--highlight.multiselect__option::after {
    background-color: #a9b5b2;
  }

  &__option--selected.multiselect__option--highlight {
    background-color: #e2e6e5 !important;
  }

  .multiselect__tag-icon:after {
    content: "×";
    color: #434f4c;
    font-size: 14px;
  }
  &__single {
    background: transparent;
    margin-bottom: 0;
  }

  &__tags {
    padding-top: 0px;
    min-height: 38px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: unset;
    appearance: none;
    border-width: 1px;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    border-color: rgb(209, 213, 219);
    line-height: 1.25rem;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

    &-wrap {
      padding-top: 6px;
    }
  }

  &__tag {
    background-color: #a9b5b2 !important;
    border-radius: 12.5px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    padding: 7px 14px 7px 11px;
    margin-bottom: 0;

    span {
      padding-right: 8px;
    }

    &-icon {
      padding-right: 24px;
      line-height: 24.5px;

      &:after {
        color: #FFFFFF;
        margin: auto 0 auto 8px;
      }

      &:hover {
        background: transparent;
      }
    }
  }

  &__input.focus-visible {
    padding: 7px 0;
  }

  &__input {
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    width: 0;
    --tw-ring-offset-width: 0;
    margin-bottom: 0;
  }

  &__input:focus {
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &__arrow {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &__placeholder {
    color: #a9b5b2;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3px;
  }

  &__spinner {
    right: 3px;
    top: 6px;

    &:before,
    &:after {
      border-top-color: #a9b5b2;
    }
  }

  &__option {
    font-size: 14px;
    //font-family: $font-family-sans-serif;
    font-weight: 400;

    &--highlight {
      background: #a9b5b2;

      &:after {
        background: #a9b5b2;
      }
    }
  }
}

.validation-error::v-deep .multiselect {
  &__tags {
    border-color: #fca5a5;
    color: #7f1d1d;
  }

  &__placeholder {
    color: #fca5a5;
  }
}
</style>
