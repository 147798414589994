import {createRouter, createWebHistory} from "vue-router";
import JwtService from '@/services/jwt.service';
import {useAuth} from "@/stores/auth";

import AppLayout from "@/views/layouts/AppLayout";
import DashboardView from "@/views/DashboardView";

const routes = [
    {
        path: "/",
        component: AppLayout,
        beforeEnter: authGuard,
        children: [
            {
                path: "/",
                component: DashboardView,
                name: "dashboard",
                meta: {title: "Dashboard"},
                redirect: {name: "monitor.index"},
            },
            {
                path: "/analytics",
                component: () => import("@/views/analytics/VAnalytics"),
                name: "analytics",
                redirect: {name: "monitor.index"},
                meta: {title: "Analytics"},
                children: [
                    {
                        path: "/monitors",
                        component: () => import("@/views/analytics/monitor/MonitorIndex"),
                        name: "monitor.index",
                        meta: {title: "Monitors"},
                    },
                    {
                        path: "/monitor/create",
                        component: () => import("@/views/analytics/monitor/MonitorSave"),
                        name: "monitor.create",
                        meta: {title: "Create Monitor"},
                    },
                    {
                        path: "/monitor/:monitor/update",
                        component: () => import("@/views/analytics/monitor/MonitorSave"),
                        name: "monitor.update",
                        meta: {title: "Monitor Settings"},
                    },
                    {
                        path: "/monitors/:monitor",
                        component: () => import("@/views/analytics/monitor/MonitorShow"),
                        name: "monitor.show",
                    },
                    {
                        path: "/monitors/:monitor/editions",
                        component: () => import("@/views/analytics/monitor/MonitorEditions"),
                        name: "monitor.editions",
                    },
                    {
                        path: "/articles",
                        component: () => import("@/views/analytics/VArticles"),
                        name: "articles",
                        meta: {title: "Articles"},
                    },
                    {
                        path: "/articles-without-data",
                        component: () => import("@/views/analytics/VArticleWithoutData"),
                        name: "articles-without-data",
                        beforeEnter: adminGuard,
                        meta: {title: "Articles Without Data"},
                    },
                    {
                        path: "/ai-articles",
                        component: () => import("@/views/analytics/admin/VArticlesAI"),
                        name: "ai-articles",
                        meta: {title: "AI Articles"},
                    },
                    {
                        path: "/sources",
                        component: () => import("@/views/analytics/VSources"),
                        name: "sources",
                        meta: {title: "Sources"},
                    },
                    {
                        path: "/searches",
                        component: () => import("@/views/analytics/VSearches"),
                        name: "searches",
                        meta: {title: "Searches"},
                    },
                    {
                        path: "/metrics",
                        component: () => import("@/views/analytics/VMetrics"),
                        name: "metrics",
                        meta: {title: "Metrics"},
                    },
                    {
                        path: "/users",
                        component: () => import("@/views/analytics/admin/user/UserIndex"),
                        name: "user.index",
                        beforeEnter: adminGuard,
                        meta: {title: "Users"},
                    },
                    {
                        path: "/user/create",
                        component: () => import("@/views/analytics/admin/user/UserSave"),
                        name: "user.create",
                        beforeEnter: adminGuard,
                        meta: {title: "Create User"},
                    },
                    {
                        path: "/user/:user/update",
                        component: () => import("@/views/analytics/admin/user/UserSave"),
                        name: "user.update",
                        beforeEnter: adminGuard,
                        meta: {title: "Update User"},
                    },
                ],
            },
        ],
    },
    {
        path: "/login",
        component: () => import("@/views/LoginView"),
        name: "login",
        meta: {title: 'Login'},
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import("@/views/errors/404View"),
        name: "not-found",
        meta: {title: "Not Found"}
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.afterEach((to) => {

    if (to.meta.title) {
        to.meta.title = `${to.meta.title} | ${process.env.VUE_APP_TITLE}`;
    }

    document.title = to.meta.title || process.env.VUE_APP_TITLE;
});

export default router;

async function authGuard(to, from, next) {
    const store = useAuth();
    await store.checkAuth();

    const isAuthenticated = JwtService.getToken();
    if (isAuthenticated) {
        next();
    } else {
        next({ name: 'login' });
    }
}

async function adminGuard(to, from, next) {
    const store = useAuth();

    if (store.currentUser.isAdmin) next();
    else next('/');
}

//todo zabraniti login kad je ulogovan
