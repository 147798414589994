import app from "@/app";

import "@/app/directives";
import "@/app/plugins";
import "@/app/components";
import ApiService from "@/services/api.service";
import Toast, { useToast } from "vue-toastification";
import VueApexCharts from "vue3-apexcharts";

import "@/main.css";
import "vue-toastification/dist/index.css";
import VueClipboard from 'vue3-clipboard';

ApiService.init();

app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});
app.use(VueClipboard);
app.use(VueApexCharts);

window.toastr = useToast();

app.mount('#app');
