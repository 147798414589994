const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
    content: [
        "./public/index.html",
        "./src/**/*.{vue,js}",
    ],
    theme: {
        fontFamily: {
            heading: ['Montserrat', ...defaultTheme.fontFamily['sans']],
            sans: ['Lato', ...defaultTheme.fontFamily['sans']]
        },
        extend: {
            colors: {
                smoke: {
                    50: '#f1f3f2',
                    100: '#e2e6e5',
                    200: '#c5cecc',
                    300: '#a9b5b2',
                    400: '#8c9d99',
                    500: '#6f847f',
                    600: '#596a66',
                    700: '#434f4c',
                    800: '#2c3533',
                    900: '#161a19',
                },
                primary: {
                    50: "var(--primary-50)",
                    100: "var(--primary-100)",
                    200: "var(--primary-200)",
                    300: "var(--primary-300)",
                    400: "var(--primary-400)",
                    500: "var(--primary-500)",
                    600: "var(--primary-600)",
                    700: "var(--primary-700)",
                    800: "var(--primary-800)",
                    900: "var(--primary-900)"
                },
            },
        },
    },
    plugins: [
        require('@tailwindcss/forms'),
    ],
}
