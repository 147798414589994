<template>
  <Popper v-bind="$attrs"
          class="popper-theme--tooltip"
          offsetDistance="8"
          hover
  >
    <slot></slot>

    <template #content="props">
      <slot name="content" v-bind="props"/>
    </template>
  </Popper>
</template>

<script>
import {defineComponent} from "vue";
import Popper from "vue3-popper";

export default defineComponent({
  name: "VTooltip",
  components: {
    Popper,
  },
});
</script>

<style scoped>
.popper-theme--tooltip {
  --popper-theme-background-color: #6f6e6f;
  --popper-theme-background-color-hover: #6f6e6f;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 8px;
  --popper-theme-box-shadow: none;
}

:deep(.popper) {
  font-size: 13px;
  pointer-events: none;
}
</style>
